/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const track = require('./src/helpers/etracker').default;

// Polyfills für IE 10 (@see https://github.com/gatsbyjs/gatsby/issues/2177#issuecomment-382280801)
// React braucht diese Polyfills, und die werden nicht automatisch von Babel gepolyfilled, da es in
// node_modules hängt.
require('core-js/fn/set');
require('core-js/fn/map');

exports.onClientEntry = () => {};

// etracker Mappings von LOTTO
const translateToEtrackerArea = path => {
    if (path === '/') {
        return 'SPJF Startseite';
    }

    if (/\/online-bewerbung.*/.test(path)) {
        return 'Online-Bewerbung';
    }

    return path;
};

// Track pageview with etracker
exports.onRouteUpdate = ({ location }) => track(translateToEtrackerArea(location.pathname));
