/**
 * Minimal-Wrapper für etracker
 * @param {string} areas
 *
 * @example track('Online-Bewerbung abgeschickt')
 */
export default areas => {
    if (typeof window.et_eC_Wrapper !== 'function') {
        return;
    }

    // eTracker scheint manche Events zu "verschlucken", daher wird ein kleiner
    // Versatz eingeführt, mit dem das Tracking etwas verzögert werden soll
    window.setTimeout(
        () =>
            window.et_eC_Wrapper({
                et_et: 'd3geFx',
                et_pagename: 'Sportjugendfoerderpreis',
                et_areas: areas,
                et_target: '',
                et_tval: '',
                et_tonr: '',
                et_tsale: 0,
                et_basket: '',
                et_cust: 0,
            }),
        100
    );
};
