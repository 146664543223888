// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-downloads-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\downloads.jsx" /* webpackChunkName: "component---src-pages-downloads-jsx" */),
  "component---src-pages-impressum-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jury-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\jury.jsx" /* webpackChunkName: "component---src-pages-jury-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-online-bewerbung-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\online-bewerbung.jsx" /* webpackChunkName: "component---src-pages-online-bewerbung-jsx" */),
  "component---src-pages-preise-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\preise.jsx" /* webpackChunkName: "component---src-pages-preise-jsx" */),
  "component---src-pages-rueckblick-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\rueckblick.jsx" /* webpackChunkName: "component---src-pages-rueckblick-jsx" */),
  "component---src-pages-teilnahmebedingungen-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\teilnahmebedingungen.jsx" /* webpackChunkName: "component---src-pages-teilnahmebedingungen-jsx" */),
  "component---src-pages-ueber-den-sjfp-jsx": () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\src\\pages\\ueber-den-sjfp.jsx" /* webpackChunkName: "component---src-pages-ueber-den-sjfp-jsx" */)
}

exports.data = () => import("C:\\www\\customers\\brandperfection\\lottobw-sportjugend-foerderpreis\\frontend\\.cache\\data.json")

